import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import OrderRAPI from "../API/OrderRAPI";
import Moment from 'moment';
import 'moment/locale/ru';
import ClientCard from "../components/UI/ClientCard";
import {ClientType, MastersType, OrderItemType, OrderType} from "../models/OrderModels";
import TimeLine from "../components/UI/TimeLine";
import ItemCard from '../components/Order/ItemCard';
import {useTypedSelector} from "../hooks/useTypedSelector";
import StatCard from "../components/UI/StatCard";
import InfoCard from "../components/UI/InfoCard";
import Modal from "../components/UI/Modal";
import PaymentAdd from "../components/PaymentAdd";
import {toast} from "react-toastify";
import IssueModal from "../components/Order/IssueModal";
import AddItem from "../components/Order/AddItem";
import ItemRAPI from "../API/ItemRAPI";
import ClientCreateModal from "../components/Order/ClientCreateModal";
import {useTitle} from "../hooks/useTitle";
import {useAccess} from "../hooks/useAccess";

import ru from 'date-fns/locale/ru';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

const OrderDetail = () => {
    let {id} = useParams()

    useTitle('Заказ ' + id)

    const [oid, setOid] = useState<number>(0)

    const [order, setOrder] = useState<OrderType>()
    const [items, setItems] = useState<OrderItemType[]>([])

    const [filterLog, setFilterLog] = useState('')

    const masters = useTypedSelector(state => state.masters)

    function filterMasters(masters: MastersType[]): MastersType[] {
        return masters.filter((x) => {
            const roles = x.roles.map(el => el.slug)
            return x.active && roles.includes('master')
        })
    }

    const activeMasters = filterMasters(masters.masters)

    function onlyUnique(value: any, index: any, array: any[]) {
        return array.indexOf(value) === index;
    }

    function parseStatuses() {
        if (typeof order != 'undefined') {
            const statuses = [];
            if (typeof order.items != 'undefined') {
                for (const x in order.items) {
                    statuses.push(parseInt(order.items[x].status))
                }
            }

            order.statuses = statuses.filter(onlyUnique)
        }
    }

    const [fetchData, isLoading, errorData] = useFetching(async () => {
        const res = await OrderRAPI.getById(Number(id))
        setOrder(res)
        if (res.desired_at != null) {
            setDateDesire(res.desired_at)
        }
        
        if (typeof res.items == 'undefined') setItems([])
        else setItems(res.items)
        setDiscount(res.discount)
        setComment(res.comment)
        setOldComment(res.comment)
    })

    useEffect(() => {
        fetchData()
    }, [])


    function statusColor() {
        if (typeof order != 'undefined') {
            parseStatuses()
            if (order.statuses.includes(0)) {
                return 'secondary'
            } else if (order.statuses.includes(1)) {
                return 'warning'
            } else if (order.statuses.includes(3)) {
                return 'info'
            } else if (order.statuses.toString() === [2].toString()) {
                return 'success'
            } else {
                return 'danger'
            }
        }
    }
    function statusIcon() {
        if (typeof order != 'undefined') {
            parseStatuses()
            if (order.statuses.includes(0)) {
                return 'fa-hourglass-half'
            } else if (order.statuses.includes(1)) {
                return 'fa-suitcase'
            } else if (order.statuses.includes(3)) {
                return 'fa-money'
            } else if (order.statuses.toString() === [2].toString()) {
                return 'fa-check-square-o'
            } else {
                return 'fa-minus-circle'
            }
        } else {
            return ''
        }
    }

    async function reloadLog(orderNew: {
        payed_summ?: number
    }) {
        if (typeof order != 'undefined') {
            const log = await OrderRAPI.getLog(order.id)
            const sum = await OrderRAPI.reCalcSum(order.id)
            let payed_summ = order.payedSum
            if (typeof orderNew.payed_summ != 'undefined') {
                payed_summ = orderNew.payed_summ
            }
            setOrder({
                ...order,
                logs: log,
                totalSum: sum,
                payedSum: payed_summ
            })
        }
    }

    async function setIssued() {
        if (typeof items != 'undefined') {
            setItems(items.map((el) => {
                el.warehouse = 'Выдан'
                el.status = '2'
                return el
            }))
            reloadLog({})
        }
    }

    async function setClient(clientId: number)
    {
        if (typeof order != 'undefined') {
            const res = await OrderRAPI.setClient(order.id, clientId)

            if (!res.error) {
                setOrder({...order, client: res, discount: res.discount})
                setDiscount(res.discount)
            }
        }
    }

    async function reCalcSum() {
        // if (typeof order != 'undefined') {
        //     const res = await OrderRAPI.reCalcSum(order.id)
        //     setOrder({...order, total_summ: res})
        // }
    }

    const [modalPaymentAdd, setModalPaymentAdd] = useState(false)
    const [modalIssue, setModalIssue] = useState(false)
    const [ModalAddItem, SetModalAddItem] = useState(false)

    async function AddItemF(Item: any)
    {
        const res = await ItemRAPI.addItem(Item);

        if (!res.error) setItems([...items, {...res, services: []}]);
    }

    const [modalSetCreated, setModalSetCreated] = useState(false)

    const [dateDesire, setDateDesire] = useState((new Date).toISOString().split('T')[0])
    const [discount, setDiscount] = useState(0)
    const [oldComment, setOldComment] = useState('')
    const [comment, setComment] = useState('')
    const [IsRush, setIsRush] = useState(false)

    useEffect(function () {
        console.log(dateDesire)
    }, [dateDesire])

    async function setCreated()
    {
        if (typeof order != 'undefined') {
            let disc = discount
            if (IsRush) {
                disc = -50
                setDiscount(-50)
            }
            const res = await OrderRAPI.setCreated(order.id, dateDesire, disc);

            if (!res.error) {
                await setOrder({
                    ...order,
                    logs: res.log,
                    totalSum: res.sum,
                    discount: disc
                })

                setOid(order.id)
                setModalSetCreated(false)
                setModalPaymentAdd(true)

                let socket = new WebSocket("ws://127.0.0.1:8765");

                setTimeout(function () {
                    toast.success('Данные отправлены на печать')
                    socket.send(res.ezpl);
                }, 500);
            }
        }
    }

    async function setCommentF()
    {
        if (oldComment != comment && typeof order != 'undefined') {
            await OrderRAPI.setComment(order.id, comment)
            toast.success('Комментарий успешно изменен')
            setOldComment(comment)
            reloadLog({})
        }
    }

    const navigate = useNavigate();

    const [deleteOrderModal, setDeleteOrderModal] = useState(false)

    async function deleteOrder()
    {
        if (typeof order != 'undefined') {
            const res = await OrderRAPI.delete(order.id)
            if (res != null && res.error) {
                toast.error(res.error)
            } else {
                navigate('/orders')
            }
        }
    }
    const deletable = useAccess('delete_order')

    async function deleteItem(itemId: number)
    {
        const res = await ItemRAPI.delete(itemId)

        if (typeof items != 'undefined') {
            setItems(items.filter((el) => {
                return itemId != el.id
            }))
        }
    }

    const [ModalItemType, SetModalItemType] = useState(false)

    async function getBusy(activeDate: Date | null) {
        if (activeDate) {
            const year = activeDate.getFullYear()
            const month = activeDate.getMonth() + 1

            const busies = await OrderRAPI.getBusy(year, month)

            if (!busies.error) {
                Array.from(document.querySelectorAll('.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth)')).forEach(e => {
                    if (e.textContent && parseInt(e.textContent) in busies) {
                        // @ts-ignore
                        e.innerHTML = e.textContent + '<div>' + busies[e.textContent] + '</div>'
                    }
                })
            }
        }
    }

    function setterDate(date: any)
    {
        if (date) {
            const tzoffset = date.getTimezoneOffset() * 60000
            setDateDesire((new Date(date - tzoffset)).toISOString().split('T')[0])
        }
    }

    useEffect(function() {
        if (modalSetCreated) {
            getBusy(new Date(dateDesire))
        }
    }, [modalSetCreated])

    // @ts-ignore
    // @ts-ignore
    return (
        <div style={{marginTop: "-25px"}}>

                <div className="page-header min-height-150 border-radius-xl mt-4"
                     style={{backgroundImage: "url('../../../assets/img/curved-images/curved0.jpg')", backgroundPositionY: "50%"}}>
                    <span className="mask bg-gradient-primary opacity-6"/>
                </div>

            {typeof order != 'undefined' &&
                <div>
                    <div className="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
                        <div className="row gx-4">
                            <div className="col-auto">
                                <div className={'avatar avatar-xl position-relative bg-gradient-' + statusColor()}>
                                    <i className={'fa '+statusIcon()} aria-hidden="true" style={{fontSize: "3rem"}}/>
                                </div>
                            </div>
                            <div className="col-auto my-auto">
                                <div className="h-100">
                                    <h5 className="mb-1">
                                        {order.id}
                                    </h5>
                                    <p className="mb-0 font-weight-bold text-sm">
                                        Принят: <b>{Moment(order.createdAt).format('LLL')}</b> Срок: <b>{order.desiredAt != null ? Moment(order.desiredAt).format('LL') : 'Не указан'}</b>
                                    </p>
                                </div>
                            </div>
                            {deletable &&
                            <div style={{position: "absolute", right: '5px', width: 'fit-content'}}>
                                <button className="btn bg-gradient-danger" onClick={() => setDeleteOrderModal(true)}>Удалить</button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="container-fluid py-4 pe-0 ps-0">
                        <div className="row">
                            <div className="col-xl-9 col-md-9">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-4">
                                        <ClientCard client={order.client} setClient={setClient}/>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-4" onMouseEnter={() => setFilterLog('оплата')} onMouseLeave={() => setFilterLog('')}>
                                        <InfoCard title="Оплата" elements={[
                                            {label: 'Итого к оплате', value: Intl.NumberFormat("ru").format(order.totalSum)},
                                            {label: 'Внесенный аванс', value: Intl.NumberFormat("ru").format(order.payedSum)},
                                            {label: 'Остаток', bv: true, value: Intl.NumberFormat("ru").format(order.totalSum - order.payedSum)},
                                            {label: 'Скидка', value: Intl.NumberFormat("ru").format(discount) + '%'},
                                            {label: 'Изделий', bv: true, value: items ? items.length : 0},
                                        ]}
                                        topButton={<button className="btn btn-sm px-3 bg-gradient-primary mb-0 float-end" type="button" onClick={() => setModalPaymentAdd(true)}>Оплатить</button>}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-4">
                                        <div className="card h-100">
                                            <div className="card-header pb-0 p-3">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <h5 className=" ms-1 mb-0">Действия</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-3">
                                                <a href="/orders" target="_blank" className="btn bg-gradient-primary me-3">Выдать другой заказ</a>
                                                {id != 'new' &&
                                                    <div>
                                                        <a href={'https://endlessmind.space/render_receipt_pg/'+order.id+'/'} target="_blank" className="btn bg-gradient-primary me-3">Квитанция</a>
                                                        <button className="btn bg-gradient-primary me-3" type="button" onClick={() => setModalIssue(true)}>Выдать всё</button>
                                                    </div>
                                                }
                                                <button className={'btn bg-gradient-success me-3 ' + (id == 'new' ? 'animPulse' : '')} type="button"
                                                    onClick={() => setModalSetCreated(true)}>Срок и скидка</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-4">
                                        <div className="card h-100">
                                            <div className="card-header pb-0 p-3">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <h5 className=" ms-1 mb-0">Комментарий</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-3">
                                                <textarea className="form-control h-100" onBlur={setCommentF} onChange={(e) => setComment(e.target.value)}>{comment}</textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-12 col-lg-8 mt-4">
                                        {items && items.map((el) =>
                                            <ItemCard item={el} key={el.id} canDelete={id == 'new'} onDelete={() => deleteItem(el.id)} activeMasters={activeMasters} onMouseEnter={() => setFilterLog(el.id.toString())} onMouseLeave={() => setFilterLog('')}
                                                      reloadLog={() => reloadLog({})} reCalcSum={() => reCalcSum()}/>
                                        )}
                                        <button className="btn bg-gradient-primary float-end" type="button" onClick={() => SetModalAddItem(true)}><i className="fa fa-plus" aria-hidden="true"/> Добавить изделие</button>
                                    </div>
                                </div>
                            </div>
                            {typeof order.logs != 'undefined' && order.logs.length > 0 &&
                            <div className="col-xl-3 col-md-3">
                                <h6 className="mb-3">Журнал</h6>
                                <TimeLine masters={masters.masters} timeline={order.logs.map(el => {
                                    el.selected = false
                                    if (filterLog != '' && el.event.toLowerCase().indexOf(filterLog.toLowerCase()) != -1) {
                                        el.selected = true
                                    }
                                    return el
                                })}/>
                            </div>
                            }

                        </div>
                    </div>
                    <PaymentAdd orderId={order.id} toPay={order.totalSum - order.payedSum} payed={(sum: number) => {
                        reloadLog({payed_summ: Number(order.payedSum) + Number(sum)})
                        setModalPaymentAdd(false)
                    }} modal={modalPaymentAdd} closeModal={() => setModalPaymentAdd(false)}/>

                    <IssueModal orderID={order.id} successIssue={setIssued} closeModal={() => setModalIssue(false)} modal={modalIssue}/>
                    <AddItem
                        AddItemF={(Item: any) => AddItemF(Item)}
                        orderId={order.id}
                        orderDesiredAt={order.desiredAt}
                        closeModal={() => SetModalAddItem(false)}
                        modal={ModalAddItem}
                        skipSteps={(order.skip_steps ? order.skip_steps : [])}
                    />
                    <Modal title="Тип изделия" id="itemType" show={ModalItemType} onClose={() => SetModalItemType(false)}>
                        <div className="hmm">sdsd</div>
                    </Modal>
                    <Modal title="Удалить заказ" id="delete" show={deleteOrderModal} onClose={() => setDeleteOrderModal(false)}
                           footerButtons={<button className="btn bg-gradient-danger" onClick={deleteOrder}>Удалить</button>}>
                        <h5 className=" ms-1 mb-0">Уверены что хотите удалить заказ #{order.id}</h5>

                    </Modal>
                    <Modal title="Оформить заказ" id="setcreated" show={modalSetCreated} onClose={() => setModalSetCreated(false)}
                           footerButtons={<button className="btn bg-gradient-success" onClick={setCreated}>Оформить</button>}>
                        <div className="form-group">
                            <label>Срок заказа</label>
                            <Calendar locale="ru-RU" value={dateDesire} onChange={setterDate} onActiveStartDateChange={(x) => getBusy(x.activeStartDate)}/>
                        </div>

                        {!IsRush &&
                        <div className="form-group">
                            <label>Скидка на заказ</label>
                            <input type="number" value={discount}
                                   onChange={(e) => setDiscount(parseInt(e.target.value))} className="form-control"/>
                        </div>
                        }

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="fcustomCheck1"
                                   style={{width: "40px", height: "40px"}} checked={IsRush} onChange={(e) => setIsRush(!IsRush)}/>
                            <label className="custom-control-label" htmlFor="customCheck1"
                                   style={{marginLeft: "13px", marginTop: "13px"}}>Срочный заказ</label>
                        </div>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default OrderDetail;