import { sendReq } from "./SendRequest"

export default class WarehouseRAPI {
    static AllItems() {
        return sendReq('warehouse/items/')
    }
    static GetItems(warehouse) {
        return sendReq('warehouse/' + warehouse + '/items/')
    }
    static moveItem({id, whFrom, whTo, qty, price = 0}) {
        return sendReq('warehouse/move/', 'POST', {
            itemId: +id,
            whFrom: +whFrom,
            whTo: +whTo,
            qty: +qty,
            price: +price
        })
    }
    static PrintPrice(id, count = 1) {
        return sendReq('warehouse/items/' + id + '/print/price')
    }
    static PrintPriceInner(id) {
        return sendReq('warehouse/items/' + id + '/print/label')
    }
    static setItemPrice({id, price = 0}) {
        return sendReq('warehouse/set_price/', 'POST', {
            itemId: id,
            price: price
        })
    }

    static getWarehouses() {
        return sendReq('warehouse')
    }

    static createWarehouse(params) {
        return sendReq('warehouse', 'POST', params)
    }

    static createItem(params) {
        return sendReq('warehouse/items', 'POST', params)
    }

    static addToStock(params) {
        return sendReq('warehouse/stock/add', 'POST', params)
    }

    static removeFromStock(params) {
        return sendReq('warehouse/stock/withdraw', 'POST', params)
    }
}