import React, {useEffect, useState} from 'react';
import ServiceRAPI from "../API/ServiceRAPI";
import {toast} from "react-toastify";
import {useTitle} from "../hooks/useTitle";

const Services = () => {

    useTitle('Услуги')
    const [Service, setService] = useState([])
    const [parentID, setParentID] = useState('')
    const [toSave, setToSave] = useState(false)

    async function FetchData() {
        const res = await ServiceRAPI.Pupupu(parentID)

        if (res.price) {
            res.price = parseInt(res.price)
        } else {
            res.price = 0
        }
        setService(res)
    }

    function getPrice(price) {
        if (price > 0) {
            return Intl.NumberFormat("ru").format(price)
        } else {
            return 'Не указан'
        }
    }

    async function saveService() {
        const res = await ServiceRAPI.EditPupupu(parentID, Service)
        toast.success('Изменения сохранены')
        setToSave(false)
    }

    function changeName(e) {
        setService({...Service, name: e.target.value})
        setToSave(true)
    }

    function changePrice(e) {
        let price = ''
        if (!isNaN(parseInt(e.target.value))) {
            price = parseInt(e.target.value)
        }
        setService({...Service, price: price})
        setToSave(true)
    }

    function groupIcon(itemGroup) {
        switch (itemGroup) {
            case '1': return <i className="fa fa-shoe-prints text-lg"/>
            case '2': return <i className="fa fa-shopping-bag text-lg"/>
            case '3': return <i className="fa fa-tshirt text-lg"/>
            default: return itemGroup
        }
    }

    async function addService() {
        const params = {
            name: Service.name + ' - ',
            price: 0,
            parent: parentID,
            itemGroupId: parseInt(Service.itemGroupId)
        }

        const res = await ServiceRAPI.AddPupupu(params)

        setParentID(res.id)
    }

    async function deleteService() {
        const res = await ServiceRAPI.Delete(Service.id)

        if (!res.error) {
            setParentID(Service.parent)
        }
    }

    useEffect(() => {
        FetchData()
    }, [parentID])

    useEffect(() => {
        if (toSave) {
            saveService()
        }
    }, [Service])

    return (
        <div>
            <div className="breadcrumb">
                {Service.breadcrumbs != null && Service.breadcrumbs.map((el) =>
                    <span key={el.id} className="breadcrumb-item" onClick={() => setParentID(el.id)}>{el.name}</span>
                )}
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="">Наименование</label>
                    <input type="text" className="form-control" value={Service.name} onChange={changeName}/>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="">Стоимость</label>
                    <input type="text" className="form-control" value={Service.price} onChange={changePrice}/>
                </div>
                {![null, ''].includes(parentID) &&
                    <div className="col-lg-4 col-md-6 mb-4">
                        <label htmlFor="" style={{width: '100%'}}>Действия</label>
                        <button className="btn bg-gradient-danger" onClick={deleteService}>Удалить</button>
                    </div>
                }

                <label htmlFor="">Внутренние услуги</label>
            </div>
            <div className="row">
                {Service.items != null && Service.items.map((el) =>
                    <div key={el.id} className="col-lg-3 col-md-4 mb-4">
                        <div className="card serviceCard" onClick={() => setParentID(el.id)}>
                            <div className="card-body p-3">
                                <h6>{el.parent?'':groupIcon(el.itemGroupId)} {el.name}</h6>
                                <p className="text-sm mt-3">{getPrice(el.price)}</p>
                            </div>
                        </div>
                    </div>
    
                )}
                {parentID !== '' &&
                    <div className="col-lg-3 col-md-4 mb-4">
                        <div className="card serviceCard" onClick={addService} style={{backgroundImage: 'linear-gradient(310deg,#2b2a29,#666)', color: '#ccc'}}>
                            <div className="card-body p-3">
                                <h6 style={{color: '#fff'}}>Добавить услугу</h6>
                                <p className="text-sm mt-3">Нажмите для создания услуги</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Services;