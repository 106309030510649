import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import UserRAPI from "../API/UserRAPI";
import moment from "moment";
import Modal from "../components/UI/Modal";

const removeAtIndex = (arr, index) => {
    const copy = [...arr];
    copy.splice(index, 1);
    return copy;
};

const toggleArr = (arr, item, getValue = item => item) => {
const index = arr.findIndex(i => getValue(i) === getValue(item));
if (index === -1) return [...arr, item];
return removeAtIndex(arr, index);
};


export const UserDetail = () => {
    let {id} = useParams()
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null)
    const [modalAddParams, setModalAddParams] = useState(false)

    const emptyRule = {
        from: '',
        saleRate: 0,
        masterRate: 0,
        perDay: 0,
        perDayOne: 0,
        bonusPlan: false,
        bonusPriemka: false,
    }

    const [newRule, setNewRule] = useState(emptyRule)

    async function fetch() {
        const res = await UserRAPI.Get(id)
        if (!res.error) {
            setUserData({...res, selectedRoles: res.roles.map(el => el.id)})
            if (res.salaryParams) {
                setNewRule(res.salaryParams.slice(-1)[0])
            }
        }
    }

    async function addSalaryParam() {
        const res = await UserRAPI.addSalaryParam(id, newRule)

        if (!res.error) {
            setModalAddParams(false)
            fetch()
        }
    }

    async function saveUser() {
        const res = await UserRAPI.editUser(id, userData)
    }

    useEffect(() => {
        fetch()
    }, [])

    async function deactivate() {
        const res = await UserRAPI.deactivate(id)
        if (!res.error) {
            navigate('/users')
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                {userData &&
                <>
                    <h6>{userData && userData.fio}</h6>

                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label>ФИО</label>
                                <input type="text" value={userData.fio} onChange={(e) => setUserData({...userData, fio: e.target.value})} className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Телефон</label>
                                <input type="text" value={userData.phone} onChange={(e) => setUserData({...userData, phone: e.target.value})} className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Новый пароль</label>
                                <input type="text" value={userData.newpass} onChange={(e) => setUserData({...userData, newpass: e.target.value})} className="form-control"/>
                            </div>
                            <div className="form-group">
                                <button onClick={saveUser} className="btn bg-gradient-primary m-3 ms-0">Сохранить</button>
                                <button onClick={deactivate} className="btn bg-gradient-danger m-3">Деактивировать</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8">
                            <div className="form-group">
                                <label>Роли</label>
                            </div>
                            {userData && userData.allRoles.map(el =>
                                <div className="form-group">
                                    <div className="form-check form-switch my-auto">
                                        <input className="form-check-input" checked={userData.selectedRoles.includes(el.id)} type="checkbox"
                                        onChange={(e) => setUserData({...userData, selectedRoles: toggleArr(userData.selectedRoles, el.id)})}/>{el.desc}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className="col-lg-3">
                            <div className="form-group">
                                <label>Доступы</label>
                            </div>
                        </div> */}
                    </div>
                </>
                }
            </div>

            <div className="table-responsive">
                <table className="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7">Дата начала</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">Дата окончания</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">% от продаж</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">% от работ</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">За выход</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">За выход в одиночку</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">Бонус за план точки</th>
                            <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">Бонус за приемку</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData && userData.salaryParams.map(el =>
                        <tr>
                            <td className="align-middle text-center">
                                {moment(el.from).format('L')}
                            </td>
                            <td className="align-middle text-center">
                                {moment(el.to).format('L')}
                            </td>
                            <td className="align-middle text-center">
                                {el.saleRate}
                            </td>
                            <td className="align-middle text-center">
                                {el.masterRate}
                            </td>
                            <td className="align-middle text-center">
                                {el.perDay}
                            </td>
                            <td className="align-middle text-center">
                                {el.perDayOne}
                            </td>
                            <td className="align-middle text-center">
                                {el.bonusPlan ? <span className="text-success">Да</span> : <span className="text-danger">Нет</span>}
                            </td>
                            <td className="align-middle text-center">
                                {el.bonusPriemka ? <span className="text-success">Да</span> : <span className="text-danger">Нет</span>}
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
                <button onClick={() => setModalAddParams(true)} className="btn bg-gradient-primary m-3">Добавить правило</button>
            </div>

            <Modal id="salary-params" title="Добавить правило начисления ЗП" onClose={() => setModalAddParams(false)} show={modalAddParams} footerButtons={<button className="btn bg-gradient-success" onClick={addSalaryParam}>Сохранить</button>}>
                <div className="form-group">
                    <label>Дата начала</label>
                    <input value={newRule.from} onChange={(e) => setNewRule({...newRule, from: e.target.value})} type="date" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>% от продаж</label>
                    <input value={newRule.saleRate} onChange={(e) => setNewRule({...newRule, saleRate: parseInt(e.target.value)})} type="number" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>% от работ</label>
                    <input value={newRule.masterRate} onChange={(e) => setNewRule({...newRule, masterRate: parseInt(e.target.value)})} type="number" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>За выход</label>
                    <input value={newRule.perDay} onChange={(e) => setNewRule({...newRule, perDay: parseInt(e.target.value)})} type="number" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>За выход в одиночку</label>
                    <input value={newRule.perDayOne} onChange={(e) => setNewRule({...newRule, perDayOne: parseInt(e.target.value)})} type="number" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Бонус за план точки</label>
                    <div className="form-check form-switch my-auto">
                        <input className="form-check-input" checked={newRule.bonusPlan} onChange={(e) => setNewRule({...newRule, bonusPlan: e.target.checked})} type="checkbox"/>
                    </div>
                </div>
                <div className="form-group">
                    <label>Бонус за приемку</label>
                    <div className="form-check form-switch my-auto">
                        <input className="form-check-input" checked={newRule.bonusPriemka} onChange={(e) => setNewRule({...newRule, bonusPriemka: e.target.checked})} type="checkbox"/>
                    </div>
                </div>
            </Modal>
        </div>
    )
}