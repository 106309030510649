import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {useAccess} from "../hooks/useAccess";
import {useTypedSelector} from "../hooks/useTypedSelector";

const Sidebar = (props: {pin: boolean, disablePin: () => void, logout: () => void}) => {

    const classes = 'sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 ' + (props.pin ? 'bg-white':'')

    const accesses = useTypedSelector(state => state.itemRefs.user.accesses)

    const menuItems = [
        {title: 'Заказы', link: '/orders', access: 'orders', icon: 'fas fa-store'},
        {title: 'Календарь задач', link: '/work_list', access: 'own_calendar', icon: 'fas fa-calendar-week'},
        {title: 'Назначение мастера', link: '/appoint', access: 'orders', icon: 'fas fa-id-card-alt'},
        {title: 'Магазин', link: '/sale', access: 'orders', icon: 'fas fa-shopping-basket'},
        {title: 'Прайс', link: '/price', access: 'orders', icon: 'fas fa-tags'},
        {title: 'Клиенты', link: '/clients', access: 'clients', icon: 'fas fa-address-book'},
        {title: 'Услуги', link: '/services', access: 'service_manage', icon: 'fas fa-layer-group'},
        {title: 'Склад', link: '/warehouse', access: 'warehouse', icon: 'fas fa-warehouse'},
        {title: 'Сотрудники', link: '/units', access: 'own_zp', icon: 'fas fa-hand-holding-usd'},
        {title: 'График смен', link: '/calendar', access: 'schedule', icon: 'fas fa-calendar-day'},
        {title: 'Статистика', link: '/statistics', access: 'stat', icon: 'fas fa-chart-line'},
        {title: 'Транзакции', link: '/transactions', access: 'orders', icon: 'fas fa-money-bill'},
        {title: 'Пользователи', link: '/users', access: 'users', icon: 'fas fa-user'},
    ]

    return (
        <aside
            className={classes}
            id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                    aria-hidden="true" id="iconSidenav"></i>
                <NavLink className="navbar-brand m-0" to="/">
                    <img src="/assets/img/logos.png" className="navbar-brand-img h-100" alt="main_logo"/>
                        <span className="ms-1 font-weight-bold">{process.env.REACT_APP_TITLE}</span>
                </NavLink>
            </div>
            <hr className="horizontal dark mt-0"/>
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink onClick={props.disablePin} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to="/">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="fas fa-home"></i>
                                </div>
                                <span className="nav-link-text ms-1">Главная</span>
                            </NavLink>
                        </li>
                        {menuItems.map(menuItem =>
                            <>
                                {accesses.includes(menuItem.access) &&
                                <li className="nav-item">
                                    <NavLink onClick={props.disablePin}
                                            className={({isActive}) => (isActive ? "nav-link active" : "nav-link")}
                                            to={menuItem.link}>
                                        <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                                <i className={menuItem.icon}></i>
                                        </div>
                                        <span className="nav-link-text ms-1">{menuItem.title}</span>
                                    </NavLink>
                                </li>
                                }
                            </>
                        )}
                        <li className="nav-item">
                            <div className="nav-link" style={{cursor: 'pointer'}} onClick={props.logout}>
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="fas fa-door-open"></i>
                                </div>
                                <span className="nav-link-text ms-1">Выход</span>
                            </div>
                        </li>
                    </ul>
                </div>
        </aside>
    );
};

export default Sidebar;