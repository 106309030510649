import axios from "axios";
import { ClientType } from "../models/OrderModels";
import { sendReq } from "./SendRequest";

export default class OrderRAPI {
  static getAll(params: any) {
    return sendReq("orders", "GET", params);
  }
  static async getById(id: number) {
    return (await axios.get("orders/" + id)).data;
  }
  static async getLog(id: number) {
    return (await axios.get("orders/" + id + "/log/")).data;
  }
  static async getMainReference() {
    return (await axios.get("items-refs")).data;
  }
  static async reCalcSum(id: number) {
    return (await axios.get("orders/" + id + "/recalc/")).data;
  }
  static setIssue(id: number, code: string) {
    return sendReq("orders/" + id + "/issue/", "POST", { code: code });
  }
  static async setClient(id: number, clientId: number): Promise<ClientType> {
    return (
      await axios.post("orders/" + id + "/client/", {
        clientId: clientId,
      })
    ).data;
  }
  static setCreated(id: number, desired_at: string, discount: number) {
    return sendReq("orders/" + id + "/created/", "POST", {
      desiredAt: desired_at,
      discount: discount,
    });
  }
  static async setComment(id: number, comment: string) {
    return (
      await axios.post("orders/" + id + "/comment/", {
        comment: comment,
      })
    ).data;
  }
  static delete(id: number) {
    return sendReq("orders/" + id, "DELETE");
  }
  static addSale(items: any[]) {
    return sendReq("orders/sale/", "POST", {
      items: items,
    });
  }
  static getBusy(year: number, month: number) {
    return sendReq("item-services/busy/?year=" + year + "&month=" + month);
  }
}
