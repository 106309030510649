import axios from "axios";
import { sendReq } from "./SendRequest";

export default class ItemRAPI {
    static async setWarehouse(itemId: number, rack: string, shelf: string) {
        return (await axios.post('items/' + itemId + '/warehouse/', {
            rack: rack,
            shelf: shelf
        })).data
    }
    static setIssue(itemId: number, code: string) {
        return sendReq('items/' + itemId + '/issue/', "POST", { code: code });
    }
    static async returnWarranty(itemId: number) {
        return (await axios.post('items/' + itemId + '/return_warranty/')).data
    }
    static async setFreeze(itemId: number) {
        return (await axios.post('items/' + itemId + '/freeze/')).data
    }
    static addItem(Item: {
        order_id: number,
        group: string,
        type: string,
        material: string,
        color: string,
        desired_at: string,
        comment: string,
        sex: string,
        wear: string,
    }) {
        return sendReq('items', 'POST', Item)
    }
    static async setDesire(itemId: number, date: string, comment: string) {
        return (await axios.post('items/' + itemId + '/desire/', {
            date: date,
            comment: comment
        })).data
    }
    static async getTicket(itemId: number) {
        return (await axios.get('items/' + itemId + '/print_ticket/')).data
    }
    static async setComment(itemId: number, comment: string) {
        return (await axios.post('items/' + itemId + '/comment/', {
            comment: comment
        })).data
    }
    static delete(itemId: number) {
        return sendReq('items/' + itemId, 'DELETE')
    }
}