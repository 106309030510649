import React, {useEffect, useState} from 'react';
import WarehouseRAPI from "../API/WarehouseRAPI";
import {toast} from "react-toastify";
import Modal from "../components/UI/Modal";
import {useTitle} from "../hooks/useTitle";
import {useTypedSelector} from "../hooks/useTypedSelector";

const WarehouseMain = () => {

    useTitle('Склад')
    const accesses = useTypedSelector(state => state.itemRefs.user.accesses)
    const [warehouses, SetWarehouses] = useState([]);

    const [WH, SetWH] = useState(0)
    const [Search, SetSearch] = useState('')
    const [Items, SetItems] = useState([])

    async function printPrice(id, type = 'default') {

        let res = ''

        if (type === 'inner') {
            res = await WarehouseRAPI.PrintPriceInner(id)
        } else {
            res = await WarehouseRAPI.PrintPrice(id, 1)
        }

        let socket = new WebSocket("ws://127.0.0.1:8765");

        setTimeout(function () {
            toast.success('Данные отправлены на печать')
            socket.send(res);
        }, 500);
    }

    const EmptyMoveItem = {
        id: 0,
        whFrom: WH,
        whTo: '',
        qty: 1,
        price: 0,
        name: ''
    }
    const [MoveItem, SetMoveItem] = useState(EmptyMoveItem)
    const [MoveModal, SetMoveModal] = useState(false)

    async function moveItem() {
        try {
            if (MoveItem.whTo === '') throw Error('Выберите склад назначения')
            if (MoveItem.qty <= 0) throw Error('Введите кол-во')

            const res = await WarehouseRAPI.moveItem(MoveItem)

            if (res.error) {
                throw Error(res.error)
            }

            SetMoveModal(false)
            SetMoveItem(EmptyMoveItem)
            toast.success('Перемещение успешно создано')
        } catch (error) {
            toast.error(error.message)
        }
    }

    async function getItems() {
        let res = {}
        if (WH == 0) res = await WarehouseRAPI.AllItems()
        else res = await WarehouseRAPI.GetItems(WH)

        if (!res.error) {
            SetItems(res)
        } else {
            SetItems([])
        }
    }

    async function getWarehouses() {
        const res = await WarehouseRAPI.getWarehouses()
        SetWarehouses(res)
    }

    useEffect(function () {
        getItems()
        SetMoveItem({...MoveItem, whFrom: WH})
    }, [WH])

    useEffect(function () {
        getWarehouses()
    }, [])

    const [helpModal, setHelpModal] = useState(false)

    const EmptyPriceItem = {
        id: 0,
        price: 0,
        name: ''
    }
    const [PriceItem, SetPriceItem] = useState(EmptyPriceItem)
    const [PriceModal, SetPriceModal] = useState(false)

    async function priceForItem() {
        const res = await WarehouseRAPI.setItemPrice(PriceItem)

        if (!res.error) {
            getItems()

            SetPriceModal(false)
            SetPriceItem(EmptyPriceItem)
            toast.success('Цена успешно изменена')
        }
    }

    const [AddWhModal, SetAddWhModal] = useState(false)
    const [AddItemModal, SetAddItemModal] = useState(false)

    const emtptyWh = {
        name: ''
    }
    const emtptyItem = {
        name: '',
        barcode: '',
        sku: '',
        price: 0,
        buyPrice: 0,
    }

    const [AddWh, SetAddWh] = useState(emtptyWh)
    const [AddItem, SetAddItem] = useState(emtptyItem)

    async function addWarehouse() {
        const res = await WarehouseRAPI.createWarehouse(AddWh)

        if (!res.error) {
            getWarehouses()
            SetAddWhModal(false)
        }
    }

    async function addItem() {
        const res = await WarehouseRAPI.createItem(AddItem)

        if (!res.error) {
            getItems()
            SetAddItemModal(false)
        }
    }

    const [addToStock, setAddToStock] = useState({whId: 0, itemId: 0, itemName: '', qty: 0})
    const [addToStockModal, setAddToStockModal] = useState(false)

    async function addToStockFn() {
        const res = await WarehouseRAPI.addToStock(addToStock)

        if (!res.error) {
            setAddToStockModal(false)
            getItems()
            setAddToStock({whId: 0, itemId: 0, itemName: '', qty: 0})
        }
    }

    const [RemoveFromStock, setRemoveFromStock] = useState({whId: 0, itemId: 0, itemName: '', qty: 0})
    const [RemoveFromStockModal, setRemoveFromStockModal] = useState(false)

    async function RemoveFromStockFn() {
        const res = await WarehouseRAPI.removeFromStock(RemoveFromStock)

        if (!res.error) {
            setRemoveFromStockModal(false)
            getItems()
            setRemoveFromStock({whId: 0, itemId: 0, itemName: '', qty: 0})
        }
    }

    return (
        <div>
            <div className="card">

                <div className="card-header pb-0">
                    <div className="d-lg-flex justify-content-between">
                        <div>
                            <h5 className="mb-0">Остатки</h5>
                            <p className="text-sm mb-0">
                                Страница просмотра остатков, и пермещения по складам
                            </p>
                        </div>
                        <div>
                            <div className="btn bg-gradient-primary me-3" onClick={() => SetAddItemModal(true)}>
                                Добавить товар
                            </div>
                            <div className="btn bg-gradient-primary me-3" onClick={() => SetAddWhModal(true)}>
                                Добавить склад
                            </div>
                            <div className="btn bg-gradient-info" onClick={() => setHelpModal(true)}>
                                <i className="fas fa-info"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body px-0 pb-0">
                    <div className="table-responsive">
                        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                            <div className="dataTable-top">
                                <div className="dataTable-search">
                                    <input className="dataTable-input" placeholder="Поиск..." type="text" value={Search} onChange={e => SetSearch(e.target.value.toLowerCase())}/>
                                </div>
                                <div className="dataTable-dropdown float-end">
                                    <label>
                                        <select className="dataTable-selector" value={WH} onChange={(e) => SetWH(e.target.value)}>
                                            {warehouses.map(el =>
                                                <option value={el.id}>{el.name}</option>
                                            )}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="dataTable-container">
                                <table className="table table-flush dataTable-table" id="products-list">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Фото</th>
                                        <th>Наимнование</th>
                                        <th>Артикул</th>
                                        <th>Штрихкод</th>
                                        <th style={{width: '100px'}}>Остаток</th>
                                        <th style={{width: '100px'}}>Покупка</th>
                                        <th style={{width: '100px'}}>Продажа</th>
                                        <th>Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Items.filter((f) => f.name.toLowerCase().includes(Search) || f.sku.toLowerCase().includes(Search) || f.barcode.includes(Search)).map((el) =>
                                    <tr>
                                        <td style={{width: '60px'}}>
                                            {el.img &&
                                            <img style={{width: '60px'}} alt={el.barcode} src={el.img}/>
                                            }
                                        </td>
                                        <td className="text-sm" style={{whiteSpace: 'break-spaces'}}>
                                            <h6 className="ms-3 my-auto">{el.name}</h6>
                                            {/*<div className="text-end">Хватит на <b>XX</b> дней</div>*/}
                                        </td>
                                        <td className="text-sm">{el.sku}</td>
                                        <td className="text-sm">{el.barcode}</td>
                                        <td className="text-sm text-bold">{Intl.NumberFormat("ru").format(el.qty)}</td>
                                        <td className="text-sm">{Intl.NumberFormat("ru").format(el.buyPrice)}</td>
                                        <td className="text-sm"><span className="badge badge-secondary cursor-pointer text-dark" onClick={() => {
                                            SetPriceItem({id: el.id, name: el.name, price: parseInt(el.price)})
                                            SetPriceModal(true)
                                        }}>{Intl.NumberFormat("ru").format(el.price)}</span></td>
                                        <td style={{display: 'grid', gap: '7px', width: 'fit-content'}}>
                                            <div>
                                                <span onClick={() => printPrice(el.id)} className="btn btn-xs btn-outline-secondary py-1 mb-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>Ценник</span>
                                                <span onClick={() => printPrice(el.id, 'inner')} className="btn btn-xs btn-outline-secondary py-1 mb-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>Ценник внутренний</span>
                                            </div>
                                            {accesses.includes('warehouse_edit') ? (
                                                <div>
                                                    <span className="btn btn-xs btn-outline-dark py-1 mb-0" onClick={() => {
                                                        SetMoveItem({...MoveItem, id: el.id, name: el.name})
                                                        SetMoveModal(true)
                                                    }} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>Перемещение</span>
                                                    <span className="btn btn-xs btn-outline-dark py-1 mb-0" onClick={() => {
                                                        setAddToStock({whId: WH, itemId: el.id, itemName: el.name, qty: 0})
                                                        setAddToStockModal(true)
                                                    }} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>Приход</span>
                                                    <span className="btn btn-xs btn-outline-dark py-1 mb-0" onClick={() => {
                                                        setRemoveFromStock({whId: WH, itemId: el.id, itemName: el.name, qty: 0})
                                                        setRemoveFromStockModal(true)
                                                    }} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>Списание</span>
                                                </div>
                                            ):(
                                                <span className="btn btn-xs btn-outline-dark py-1 mb-0" onClick={() => {
                                                    SetMoveItem({...MoveItem, id: el.id, name: el.name})
                                                    SetMoveModal(true)
                                                }}>Перемещение</span>
                                            )}
                                        </td>
                                    </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal id="helpModal" title="Помощь по разделу" onClose={() => setHelpModal(false)} show={helpModal} addClass="modal-lg">
                <h5>Перемещение товара</h5>
                <p>
                    Основные материалы находятся на складе "Материалы", локальный склад "Чернышевского"
                </p>
                <ol>
                    <li>Переходим на склад "Материалы"</li>
                    <li>Находим необходимый товар</li>
                    <li>Нажимаем на кнопку "Перемещение"</li>
                    <li>Выбираем склад перемещения, поле "Куда"</li>
                    <li>Вводим кол-во для перемещения и нажимаем кнопку "Переместить"</li>
                </ol>
            </Modal>
            <Modal title="Перемещение товара" show={MoveModal} onClose={() => SetMoveModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={moveItem}>Переместить</button>}>
                <div className="form-group">
                    <label>Товар</label>
                    <input type="text" disabled value={MoveItem.name} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Куда</label>
                    <select className="form-control" value={MoveItem.whTo} onChange={(e) => SetMoveItem({...MoveItem, whTo: e.target.value})}>
                        <option value="">Выберите склад для перемещения</option>
                        {warehouses.filter(el => el.id != WH).map(el =>
                            <option value={el.id}>{el.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label>Количество</label>
                    <input type="number" value={MoveItem.qty} onChange={(e) => SetMoveItem({...MoveItem, qty: e.target.value})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Цена</label>
                    <input type="number" value={MoveItem.price} onChange={(e) => SetMoveItem({...MoveItem, price: e.target.value})} className="form-control"/>
                </div>
            </Modal>
            <Modal title="Изменение цены" show={PriceModal} onClose={() => SetPriceModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={priceForItem}>Сохранить</button>}>
                <div className="form-group">
                    <label>Товар</label>
                    <input type="text" disabled value={PriceItem.name} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Цена</label>
                    <input type="number" value={PriceItem.price} onChange={(e) => SetPriceItem({...PriceItem, price: e.target.value})} className="form-control"/>
                </div>
            </Modal>
            <Modal title="Добавить склад" show={AddWhModal} onClose={() => SetAddWhModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={addWarehouse}>Создать</button>}>
                <div className="form-group">
                    <label>Наименование</label>
                    <input type="text" value={AddWh.name} onChange={(e) => SetAddWh({...AddWh, name: e.target.value})} className="form-control"/>
                </div>
            </Modal>
            <Modal title="Добавить товар" show={AddItemModal} onClose={() => SetAddItemModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={addItem}>Создать</button>}>
                <div className="form-group">
                    <label>Наименование</label>
                    <input type="text" value={AddItem.name} onChange={(e) => SetAddItem({...AddItem, name: e.target.value})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Штрихкод</label>
                    <input type="text" value={AddItem.barcode} onChange={(e) => SetAddItem({...AddItem, barcode: e.target.value})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Артикул</label>
                    <input type="text" value={AddItem.sku} onChange={(e) => SetAddItem({...AddItem, sku: e.target.value})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Стоимость продажи</label>
                    <input type="number" value={AddItem.price} onChange={(e) => SetAddItem({...AddItem, price: parseInt(e.target.value)})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Стоимость закупки</label>
                    <input type="number" value={AddItem.buyPrice} onChange={(e) => SetAddItem({...AddItem, buyPrice: parseInt(e.target.value)})} className="form-control"/>
                </div>
            </Modal>
            <Modal title="Приход товара" show={addToStockModal} onClose={() => setAddToStockModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={addToStockFn}>Сохранить</button>}>
                <div className="form-group">
                    <label>Товар</label>
                    <input type="text" disabled value={addToStock.itemName} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Куда</label>
                    <select className="form-control" value={addToStock.whId} onChange={(e) => setAddToStock({...addToStock, whId: parseInt(e.target.value)})}>
                        <option value="">Выберите склад для пополнения</option>
                        {warehouses.map(el =>
                            <option value={el.id}>{el.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label>Количество</label>
                    <input type="number" value={addToStock.qty} onChange={(e) => setAddToStock({...addToStock, qty: parseFloat(e.target.value)})} className="form-control"/>
                </div>
            </Modal>
            <Modal title="Списание товара" show={RemoveFromStockModal} onClose={() => setRemoveFromStockModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={RemoveFromStockFn}>Сохранить</button>}>
                <div className="form-group">
                    <label>Товар</label>
                    <input type="text" disabled value={RemoveFromStock.itemName} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Куда</label>
                    <select className="form-control" value={RemoveFromStock.whId} onChange={(e) => setRemoveFromStock({...RemoveFromStock, whId: parseInt(e.target.value)})}>
                        <option value="">Выберите склад списания</option>
                        {warehouses.map(el =>
                            <option value={el.id}>{el.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label>Количество</label>
                    <input type="number" value={RemoveFromStock.qty} onChange={(e) => setRemoveFromStock({...RemoveFromStock, qty: parseFloat(e.target.value)})} className="form-control"/>
                </div>
            </Modal>
        </div>
    );
};

export default WarehouseMain;